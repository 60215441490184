import { default as indexXBYPmBjODCMeta } from "/vercel/path0/pages/[catSlug]-a[catId]/index.vue?macro=true";
import { default as auth_46clientGpfXK5QSK5Meta } from "/vercel/path0/pages/auth.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/vercel/path0/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as adreslerim_46clientImWcTswnUPMeta } from "/vercel/path0/pages/hesap/adreslerim.client.vue?macro=true";
import { default as ayarlarzU5570WAKZMeta } from "/vercel/path0/pages/hesap/ayarlar.vue?macro=true";
import { default as logoutSQle0KxYNpMeta } from "/vercel/path0/pages/hesap/logout.vue?macro=true";
import { default as siparislerim_46clientLBVfVxdMeOMeta } from "/vercel/path0/pages/hesap/siparislerim.client.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as loginHWuxY6o8eEMeta } from "/vercel/path0/pages/management/login.vue?macro=true";
import { default as _91id_93mt4GLUOBHlMeta } from "/vercel/path0/pages/management/siparisler/[id].vue?macro=true";
import { default as indexz1FAJPg94HMeta } from "/vercel/path0/pages/management/siparisler/index.vue?macro=true";
import { default as _91id_93gsT9wix28TMeta } from "/vercel/path0/pages/management/urunler/[id].vue?macro=true";
import { default as index6s6tFyFlL5Meta } from "/vercel/path0/pages/management/urunler/index.vue?macro=true";
import { default as iade_45ve_45degisimE8LQHBUt78Meta } from "/vercel/path0/pages/menu/iade-ve-degisim.vue?macro=true";
import { default as odeme_46client9hMm2EdEnVMeta } from "/vercel/path0/pages/odeme.client.vue?macro=true";
import { default as sepetw9JfziTpwcMeta } from "/vercel/path0/pages/sepet.vue?macro=true";
import { default as sifremi_45unuttum_46clientOUyyblMyusMeta } from "/vercel/path0/pages/sifremi-unuttum.client.vue?macro=true";
import { default as tesekkurler_46clientPmlPhHI88HMeta } from "/vercel/path0/pages/tesekkurler.client.vue?macro=true";
import { default as _91slug_93_45_45_91urlParams_93pJmxBxDiArMeta } from "/vercel/path0/pages/urun/[slug]--[urlParams].vue?macro=true";
export default [
  {
    name: "catSlug-acatId",
    path: "/:catSlug()-a:catId()",
    component: () => import("/vercel/path0/pages/[catSlug]-a[catId]/index.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    component: () => createClientPage(() => import("/vercel/path0/pages/auth.client.vue").then(m => m.default || m))
  },
  {
    name: "hesap-adreslerim",
    path: "/hesap/adreslerim",
    component: () => createClientPage(() => import("/vercel/path0/pages/hesap/adreslerim.client.vue").then(m => m.default || m))
  },
  {
    name: "hesap-ayarlar",
    path: "/hesap/ayarlar",
    component: () => import("/vercel/path0/pages/hesap/ayarlar.vue").then(m => m.default || m)
  },
  {
    name: "hesap-logout",
    path: "/hesap/logout",
    component: () => import("/vercel/path0/pages/hesap/logout.vue").then(m => m.default || m)
  },
  {
    name: "hesap-siparislerim",
    path: "/hesap/siparislerim",
    component: () => createClientPage(() => import("/vercel/path0/pages/hesap/siparislerim.client.vue").then(m => m.default || m))
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "management-login",
    path: "/management/login",
    meta: loginHWuxY6o8eEMeta || {},
    component: () => import("/vercel/path0/pages/management/login.vue").then(m => m.default || m)
  },
  {
    name: "management-siparisler-id",
    path: "/management/siparisler/:id()",
    meta: _91id_93mt4GLUOBHlMeta || {},
    component: () => import("/vercel/path0/pages/management/siparisler/[id].vue").then(m => m.default || m)
  },
  {
    name: "management-siparisler",
    path: "/management/siparisler",
    meta: indexz1FAJPg94HMeta || {},
    component: () => import("/vercel/path0/pages/management/siparisler/index.vue").then(m => m.default || m)
  },
  {
    name: "management-urunler-id",
    path: "/management/urunler/:id()",
    meta: _91id_93gsT9wix28TMeta || {},
    component: () => import("/vercel/path0/pages/management/urunler/[id].vue").then(m => m.default || m)
  },
  {
    name: "management-urunler",
    path: "/management/urunler",
    meta: index6s6tFyFlL5Meta || {},
    component: () => import("/vercel/path0/pages/management/urunler/index.vue").then(m => m.default || m)
  },
  {
    name: "menu-iade-ve-degisim",
    path: "/menu/iade-ve-degisim",
    component: () => import("/vercel/path0/pages/menu/iade-ve-degisim.vue").then(m => m.default || m)
  },
  {
    name: "odeme",
    path: "/odeme",
    component: () => createClientPage(() => import("/vercel/path0/pages/odeme.client.vue").then(m => m.default || m))
  },
  {
    name: "sepet",
    path: "/sepet",
    component: () => import("/vercel/path0/pages/sepet.vue").then(m => m.default || m)
  },
  {
    name: "sifremi-unuttum",
    path: "/sifremi-unuttum",
    component: () => createClientPage(() => import("/vercel/path0/pages/sifremi-unuttum.client.vue").then(m => m.default || m))
  },
  {
    name: "tesekkurler",
    path: "/tesekkurler",
    component: () => createClientPage(() => import("/vercel/path0/pages/tesekkurler.client.vue").then(m => m.default || m))
  },
  {
    name: "urun-slug--urlParams",
    path: "/urun/:slug()--:urlParams()",
    component: () => import("/vercel/path0/pages/urun/[slug]--[urlParams].vue").then(m => m.default || m)
  }
]